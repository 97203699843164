type FaviconSet = Record<string, string>;

interface Favicons {
  synvest: FaviconSet;
  trustus: FaviconSet;
}

type BrandName = keyof Favicons | null;

let faviconsSet = false; // Flag to prevent multiple executions

function createOrUpdateFaviconElement(id: string, rel: string, sizes: string | null = null, type: string | null = null) {
  let element = document.getElementById(id) as HTMLLinkElement;
  if (!element) {
    element = document.createElement('link');
    element.id = id;
    if (rel) element.rel = rel;
    if (sizes) element.sizes = sizes;
    if (type) element.type = type;
    document.head.appendChild(element);
  }
  return element;
}

function setFavicons() {
  if (faviconsSet) return; // Exit if favicons have already been set
  faviconsSet = true; // Set the flag

  const brand: BrandName = window.customEnv ? window.customEnv.current_env.toLowerCase() : null;

  const favicons: Favicons = {
    synvest: {
      favicon: '/icons/synvest/favicon.ico',
      appleTouchIcon: '/icons/synvest/apple-touch-icon.png',
      favicon16: '/icons/synvest/favicon-16x16.png',
      androidChrome192: '/icons/synvest/android-chrome-192x192.png',
      androidChrome512: '/icons/synvest/android-chrome-512x512.png',
      siteManifest: '/icons/synvest/site.webmanifest',
    },
    trustus: {
      favicon: '/icons/trustus/favicon.ico',
      appleTouchIcon: '/icons/trustus/apple-touch-icon.png',
      favicon16: '/icons/trustus/favicon-16x16.png',
      androidChrome192: '/icons/trustus/android-chrome-192x192.png',
      androidChrome512: '/icons/trustus/android-chrome-512x512.png',
      siteManifest: '/icons/trustus/site.webmanifest',
    }
  };


  const selectedFavicons: FaviconSet | null = brand && favicons[brand] ? favicons[brand] : null;

  const cacheBuster = `?v=${new Date().getTime()}`; // Generate a cache-busting query parameter

  const updateFaviconAttribute = (id: string, attributeValue: string, rel: string, sizes: string | null = null, type: string | null = null) => {
    const element = createOrUpdateFaviconElement(id, rel, sizes, type);
    element.setAttribute('href', attributeValue + cacheBuster);
  };

  if (selectedFavicons) {
    updateFaviconAttribute('favicon', selectedFavicons.favicon, 'icon', null, 'image/x-icon');
    updateFaviconAttribute('apple-touch-icon', selectedFavicons.appleTouchIcon, 'apple-touch-icon', '180x180');
    updateFaviconAttribute('favicon-16x16', selectedFavicons.favicon16, 'icon', '16x16', 'image/png');
    updateFaviconAttribute('android-chrome-192x192', selectedFavicons.androidChrome192, 'icon', '192x192', 'image/png');
    updateFaviconAttribute('android-chrome-512x512', selectedFavicons.androidChrome512, 'icon', '512x512', 'image/png');
    updateFaviconAttribute('web-manifest', selectedFavicons.siteManifest, 'manifest');
  } else {
    console.warn('No favicons selected');
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (window.customEnv) {
    setFavicons();
  } else {
    console.warn('window.customEnv is not defined yet, waiting for customEnvReady');
  }
});

document.addEventListener("customEnvReady", () => {
  setFavicons();
});

setTimeout(() => {
  if (!faviconsSet && window.customEnv) {
    console.warn('Favicons not set after 5 seconds, setting them now');
    setFavicons();
  }
}, 5000);
