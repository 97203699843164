<template>
  <div class="wrapper-3">
    <div class="wrapper-2">
      <div class="wrapper-1">
        <div
          v-for="(item, index) in synvestMenu"
          :key="index"
          class="nav-group-container"
        >
          <div class="nav-accordion" @click="toggleAccordion(index)">
            <p class="nav-title">{{ item.name }}</p>
            <ArrowExpand
              :direction="state.isOpen.includes(index) ? 'down' : 'up'"
            />
          </div>

          <div
            :class="{
              'nav-group': index !== synvestMenu.length,
              closed: state.isOpen.includes(index),
            }"
          >
            <div
              v-for="(menuItem, index) in item.items"
              :key="index"
              :class="[
                'nav-button-container',
                menuItem.active ? 'active' : 'inactive',
              ]"
            >
              <div
                class="flex flex-row justify-between items-center gap-1 py-2 px-4"
                @click="() => navigateToFlow(menuItem.route, activePage)"
                v-if="menuItem.route"
              >
                <span>
                  {{ menuItem.name }}
                </span>
                <span v-if="menuItem.count" class="font-semibold">
                  {{ menuItem.count }}
                </span>
              </div>

              <div
                class="flex flex-row justify-between items-center gap-1 py-2 px-4"
                v-if="menuItem.href"
              >
                <a :href="menuItem.href" target="_blank">
                  {{ menuItem.name }}
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div v-if="!isNewUserFlag" class="mijn-instellingen-wrapper">
          <SettingsIcon />
          <RouterLink
            class="mijn-instellingen-link"
            to="/mijn-instellingen/persoonlijke-informatie"
            >Mijn instellingen</RouterLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive } from "vue";
import synMenuItems from "@/helpers/useSynvestMenu";
import ArrowExpand from "@/assets/ArrowExpand.vue";
import SettingsIcon from "@/assets/SettingsIcon.vue";
import { getSynvestActivePage } from "@/store/synvestFlow";
import { navigateToFlow } from "@/helpers/navigationHelpers";
import { getCachedPersonalInfo, getPersonalInfo } from "@/store/mainInterface";

const { synvestMenu } = synMenuItems();
const activePage = computed(() => getSynvestActivePage());

const state = reactive<{
  isOpen: number[];
  isNewUser: boolean;
}>({
  isOpen: [],
  isNewUser: false,
});

const isNewUserFlag = computed(() => getCachedPersonalInfo()?.Id === 0 ?? true);

function toggleAccordion(index: number) {
  if (state.isOpen.includes(index)) {
    state.isOpen = state.isOpen.filter((i) => i !== index);
  } else {
    state.isOpen.push(index);
  }
}

onBeforeMount(async () => {
  const cachedPersonalInfo = getCachedPersonalInfo();
  if (!cachedPersonalInfo) {
    const personalInfo = await getPersonalInfo();
    state.isNewUser = personalInfo.Id === 0;
  } else {
    state.isNewUser = cachedPersonalInfo.Id === 0;
  }
});
</script>

<style scoped lang="scss">
.wrapper-3 {
  @apply hidden lg:block md:w-1/5 bg-yellow-bg;
}

.wrapper-2 {
  @apply flex justify-end;
}

.wrapper-1 {
  @apply md:w-2/3;
}

.nav-group-container {
  @apply pb-5;

  .nav-accordion {
    @apply mb-5 flex justify-between items-center gap-1 cursor-pointer;

    .nav-title {
      @apply text-label-color-4 text-base font-bold pt-1;
    }

    .transform {
      transform: rotate(180deg);
    }
  }

  .nav-group {
    @apply pb-5 border-b-[1px];

    &.closed {
      display: none;
    }

    .nav-button-container {
      @apply bg-white mb-2 text-lg border-l-3 hover:border-label-color-2 hover:text-label-color-2 cursor-pointer text-left;

      &.active {
        @apply text-label-color-2 border-label-color-2;
      }

      &.inactive {
        @apply border-label-grey-2 text-label-grey-2;
      }
    }
  }
}

.mijn-instellingen-wrapper {
  @apply flex gap-2 px-3 py-3 rounded-lg border-1 border-label-color-2 cursor-pointer;

  .mijn-instellingen-link {
    @apply text-label-color-2 leading-4 text-sm;
  }
}
</style>
